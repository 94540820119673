import { useCallback, useEffect, useState } from 'react';
import Moment from 'moment';

import { useSearchParams } from 'react-router-dom';

import { PersonAdd } from '@mui/icons-material';
import { Box, Button, Container, Typography } from '@mui/material';

import { DashboardStyles } from '../assets/styles/admin-dashboard.styles.js';
import DashboardTable from '../dashboard/dashboard.table.js';
import AdminDashboardTable from './admin-dashboard.table';
import DialogCreateAdmin from './create-admin.dialog';
import ReportsService from '../../core/services/reports.service';
import AppHelper from '../../core/helpers/AppHelper';
import { useUI } from '../../core/context/ui';
import { useSelector } from 'react-redux';
import LoaderWidget from '../../core/widgets/loader/loader.widget';
import download from '../../assets/images/download.svg';
import DialogMonthlyReport from './monthly-report.dialog.js';

const AdminDashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const viewParams = searchParams.get('view');
  const { snackbarUI } = useUI();
  const [loader, setLoader] = useState(false);
  const reportsService = new ReportsService();
  const { user } = useSelector((state) => state.user);
  const accessToken = user?.token;
  const [openDialogCreateAdmin, setOpenDialogCreateAdmin] = useState(false);
  const [openDlg, setOpenDlg] = useState(false);

  // monthly report dialog
  const [openMRDialog, setOpenMRDialog] = useState(false);

  const [viewSelected, setViewSelected] = useState(viewParams ? viewParams : 'views'); // admins or customers
  const styles = DashboardStyles();

  const ridersType = [
    { id: '1', riderType: 'ascap' },
    { id: '2', riderType: 'sesac' },
    { id: '3', riderType: 'bmi' },
    { id: '4', riderType: 'gmr' },
    { id: '5', riderType: 'monthly' },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleViewSelected = useCallback((view) => {
    setViewSelected(view);
    setSearchParams({ view });
  }, [setSearchParams]);

  const getRiders = async (riderType) => {
    try {
      reportsService.setAccessToken(accessToken)
      // eslint-disable-next-line no-unused-vars
      if (riderType !== 'monthly') {
        setLoader(true);
        const response = await reportsService.getRiders(riderType);
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let file = document.createElement('a');
        file.href = url;
        file.download = `${riderType}_rider_${Moment().format('YYYYMMDDHms')}.xlsx`;
        file.click();
        setLoader(false);
      } else {
        setOpenMRDialog(true)
      }
    } catch (e) {
      setLoader(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    handleViewSelected(viewParams);
  }, [viewParams, handleViewSelected]);  

  return (
    <Container style={{ maxWidth: '95%' }}>
      <Box sx={styles.titleWrap}>
        <Box>
          <Typography variant='h1' sx={styles.title}>
            Admin Dashboard
          </Typography>
          <Typography variant='h5' sx={styles.subTitleAdmin}>
            Current Information
          </Typography>
        </Box>

        <Box sx={styles.wrapBtn}>
          {ridersType.map((data) => (
            <Button key={data.id} onClick={() => getRiders(`${data.riderType}`)} sx={[styles.btn, styles.btnPrimaryLight, styles.downloadBtn]}>
                <Box component='img' src={download} sx={styles.ico} />
                {data.riderType} {data.riderType !== 'monthly' ? 'Rider' : 'Report'}
              </Button>
          ))}
          {
            (viewParams === 'admins')
              &&
                <Button
                  sx={[styles.btn, styles.btnPrimaryLight, styles.downloadBtn]}
                  onClick={() => {
                    setOpenDialogCreateAdmin(true);
                  }}
                >
                  <PersonAdd />
                  Create new admin
                </Button>
          }
        </Box>
      </Box>
      {viewSelected === 'admins' ? (
        <AdminDashboardTable openDlg={openDlg} setOpenDlg={setOpenDlg} />
      ) : (
        <DashboardTable />
      )}
      <DialogCreateAdmin
        openDialog={openDialogCreateAdmin}
        setOpenDialog={setOpenDialogCreateAdmin}
      />
      {
        openMRDialog && (<DialogMonthlyReport
        openDialog={openMRDialog}
        setOpenDialog={setOpenMRDialog}
      />)
      }
       <LoaderWidget loader={loader} />
    </Container>
  );
};

export default AdminDashboard;
